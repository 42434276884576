<template>
  <div class="relative w-full h-full">
    <div class="absolute w-[37%] h-[37%] left-1/2 top-[37.5%] -translate-x-1/2">
                    <svg
	 viewBox="0 0 100 100"
                        ref="olhoRoda"
                        class="olhoRoda"
                        
                        fill="none"
                         :style="`transform: rotate(${rotationDegrees - 40}deg)`"
                    >
<g>
<path fill="currentColor" d="M49.998,0C22.387,0,0.002,22.384,0.002,50c0,27.616,22.384,50,49.996,50c27.616,0,50-22.384,50-50
	C99.998,22.384,77.614,0,49.998,0z M73.232,43.878c-1.808,1.632-4.044,1.975-5.603,1.975c-4.087,0-8.518-2.207-11.858-5.894
	c-2.756-3.052-4.426-6.702-4.688-10.27c-0.241-3.134,0.67-5.796,2.546-7.496c1.807-1.636,4.048-1.979,5.607-1.979
	c4.087,0,8.518,2.207,11.858,5.895C76.405,31.986,77.383,40.126,73.232,43.878z"/>
                  </g>
                    </svg>
                </div>
                <div class="absolute w-full h-full left-0 bottom-0" :class="corFundo ?? 'text-neutral-100'">
                    <svg
	 viewBox="0 0 200 200"
                        
                        
                    >
                    <mask id="olhoMascara3">
                      <path fill="white" :d="'M0 0 C66.66 0 133.33 0 200 0 200 66.66 200 133.33 200 200 133.33 200 66.66 200 0 200 0 133.33 0 66.66 0 0 z '"></path>
                      <path fill="black" :d="isScrolling ? olho.aberto : olho.fechado"></path>
</mask>
                    <path fill="currentColor" mask="url(#olhoMascara3)" :d="'M0 0 C66.66 0 133.33 0 200 0 200 66.66 200 133.33 200 200 133.33 200 66.66 200 0 200 0 133.33 0 66.66 0 0 z '"></path>
                    </svg>
                </div>
      <svg class="relative w-full h-full" viewBox="0 0 200 200">
<!-- Define the mask -->
<mask id="olhoMascara">
  <path fill="white" :d="isScrolling ? olho.aberto : olho.fechado"></path>
</mask>

<!-- Apply the mask to the path -->
<!-- <path d="M127.904,77.385l-28.887-8.706l-25.989,7.554c0,0-6.011,12.446-6.85,33.235 c-0.84,20.789,8.196,33.254,8.196,33.254l26.625,7.535l26.905-7.681C136.791,132.474,135.016,92.679,127.904,77.385z M104.016,110.179c0,0-8-7-8-11s9-13,9-13c3,3,8,11,8,14S104.016,110.179,104.016,110.179z" mask="url(#olhoMascara)" fill="currentColor" /> -->

<path fill="none" stroke="currentColor" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" :d="isScrolling ? sobrancelha.fechado : sobrancelha.aberto"/>

<path fill="none" stroke="currentColor" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" :d="isScrolling ? olho.aberto : olho.fechado"></path>
</svg>
  </div>
</template>

<script setup>
import { ref, defineComponent, onMounted, onBeforeUnmount, watch } from "vue";
import {
    debouncedWatch,
    throttledWatch,
    useMouse,
    useWindowSize,
  } from "@vueuse/core";

  defineProps({
    corFundo: String
  })

const { x: mouseX, y: mouseY } = useMouse();
      const { width, height } = useWindowSize();
  
      // Here we are setting the variables we will need, including the olhoRoda svg reference
      const olhoRoda = ref(null);
      const rotationDegrees = ref(0);
      const olhoRodaLocation = ref(undefined);
  
      // Get the olhoRoda loaction on mounted
      onMounted(() => {
        olhoRodaLocation.value = olhoRoda.value.getBoundingClientRect();
        
        window.addEventListener("scroll", (event) => {
      isScrolling.value = true;
  });
  
  window.addEventListener("scrollend", (event) => {
      isScrolling.value = false;
  });
});

// Remove event listeners on component unmount
// onBeforeUnmount(() => {
// window.removeEventListener('scroll');
// window.removeEventListener('scrollEnd');
// });
  
      // Use the debounce watch function to get the
      // olhoRoda location when the window height and width cahnge
      debouncedWatch(
        [width, height],
        () => {
          olhoRodaLocation.value = olhoRoda.value.getBoundingClientRect();
        },
        { debounce: 200 }
      );
  
      // Using the throttle watch function, on mouse x & y change
      throttledWatch(
        [mouseX, mouseY],
        ([x, y]) => {
          if (olhoRodaLocation.value) {
            // This calculates the radian offset between the olhoRoda and the mouse
            const radianDegrees = Math.atan2(
              x - olhoRodaLocation.value.right,
              y - olhoRodaLocation.value.top
            );
            // Now we need to convert it into a degrees value we can use in css
            rotationDegrees.value = radianDegrees * (180 / Math.PI) * -1 + 180;
          }
        },
        // Because an avenerage screen only refreshes at 60 hertz,
        // we can throttle this to 60 times per second
        { throttle: 1000 / 60 }
      );





const isScrolling = ref(false); // Your variable
const paths = ref([
  "M10 10 L90 10 L50 90 Z", // Initial path
  "M10 90 L90 90 L50 10 Z"  // Path to animate to
]);

  const olho = {
      aberto: "M35.016,109.478c0,0,32,0.522,64,0.522s67.964-0.522,67.964-0.522s-32-0.542-65.982-0.542S35.016,109.478,35.016,109.478z",
      fechado: "M35.016,108.679c0,0,32-40,64-40s67.964,40,67.964,40s-32,41.578-65.982,41.578S35.016,108.679,35.016,108.679z",
  };

  const baseTopo = {
      aberto: "M-26-14.5v232h246.6v-232H-26z M101,150.3c-34,0-66-41.6-66-41.6s32-40,64-40s68,40,68,40S135,150.3,101,150.3z",
      fechado: "M-26-14.5v232h246.6v-232H-26z M101,110.7c-34,0-66-1.3-66-1.3s32-1.2,64-1.2s68,1.2,68,1.2 S135,110.7,101,110.7z",
  };
  
  const sobrancelha = {
      aberto: "M31.016,72.679c0,0,31.964-29.227,69.982-29.227s70.985,29.227,70.985,29.227",
      fechado: "M31,72.7c0,0,32-22.2,70-22.2s71,22.2,71,22.2",
  };
  
  const olho2 = {
      aberto: "M35.016,108.679c0,0,32-40,64-40s67.964,40,67.964,40s-32,41.578-65.982,41.578S35.016,108.679,35.016,108.679z",
      fechado: "M35,109.4c0,0,32-1.2,64-1.2s68,1.2,68,1.2s-32,1.3-66,1.3S35,109.4,35,109.4z",
  };

  const iris = {
      aberto: "M127.904,109.886L99.016,110l-25.989-0.098c0,0-6.011-0.162-6.85-0.433c-0.84-0.271,8.196-0.434,8.196-0.434 l26.625-0.098l26.905,0.1C136.791,109.168,135.016,109.687,127.904,109.886z M104.016,109.459c0,0-8,0.091-8,0.143s9,0.17,9,0.17 c3-0.039,8-0.143,8-0.183S104.016,109.459,104.016,109.459z",
      fechado: "M127.904,77.385l-28.887-8.706l-25.989,7.554c0,0-6.011,12.446-6.85,33.235 c-0.84,20.789,8.196,33.254,8.196,33.254l26.625,7.535l26.905-7.681C136.791,132.474,135.016,92.679,127.904,77.385z M104.016,110.179c0,0-8-7-8-11s9-13,9-13c3,3,8,11,8,14S104.016,110.179,104.016,110.179z",
  };

const currentIndex = ref(0);

const path = ref(paths.value[currentIndex.value]);

const changePath = () => {
  currentIndex.value = 1 - currentIndex.value; // Toggle between 0 and 1
  path.value = paths.value[currentIndex.value]
};

watch(isScrolling, (newX, oldX) => {
  // You can add additional logic here based on the changes in 'x'
  changePath();
});
</script>

<style scoped>
path {
  transition: d 300ms ease; /* CSS transition for the 'd' property with a duration of 1 second */
}
</style>
